define("discourse/plugins/discourse-uipath/discourse/initializers/uipath-initializer", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/models/login-method", "discourse-i18n"], function (_exports, _object, _pluginApi, _loginMethod, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "uipath-initializer",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.37", api => {
        if (container.lookup("service:site-settings").uipath_custom_signup_enabled) {
          // Add a parameter to the signup flow, so the IDP starts
          // with the sign up screen
          api.modifyClass("route:application", {
            pluginId: "discourse-uipath",
            actions: {
              showCreateAccount() {
                const loginMethod = (0, _loginMethod.findAll)().find(m => m.name === "oidc");
                return loginMethod.doLogin({
                  params: {
                    type: "signup"
                  }
                });
              }
            }
          });
        }

        // Check that the terms have been accepted when creating a new account
        // TODO: remove once core drops modal signup/login routes
        api.modifyClass("component:modal/create-account", Superclass => class extends Superclass {
          createAccount() {
            if (this.authOptions.requires_terms_acceptance && !this.authOptions.termsAccepted) {
              this.set("flash", (0, _discourseI18n.i18n)("uipath.terms_error_message"));
              return;
            }
            super.createAccount(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "createAccount", [_object.action]))();
        });

        // Check that the terms have been accepted when creating a new account
        api.modifyClass("controller:signup", Superclass => class extends Superclass {
          createAccount() {
            if (this.authOptions.requires_terms_acceptance && !this.authOptions.termsAccepted) {
              this.set("flash", (0, _discourseI18n.i18n)("uipath.terms_error_message"));
              return;
            }
            super.createAccount(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "createAccount", [_object.action]))();
        });
      });
    }
  };
});